import { render, staticRenderFns } from "./App.vue?vue&type=template&id=96c12be4"
var script = {}
import style0 from "./App.vue?vue&type=style&index=0&id=96c12be4&prod&lang=css"
import style1 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports